function checkEnum(value) {
  return this.id === value.toUpperCase();
}

export const CancelInviteStatus = {
  CANCEL_INVITE_IN_REVIEW: {
    id: "EM_ANALISE",
    name: "Em Análise",
    check: checkEnum
  },
  CANCEL_INVITE_APPROVED: {
    id: "APROVADO",
    name: "AprovadO",
    check: checkEnum
  },
  CANCEL_INVITE_DISAPPROVED: {
    id: "REPROVADO",
    name: "Reprovado",
    check: checkEnum
  },
  CANCEL_INVITE_CANCELED: {
    id: "CANCELADO",
    name: "Cancelado",
    check: checkEnum
  }
};
