<template>
  <v-layout d-flex flex-column class="fill">
    <v-row dense>
      <v-col cols="12" md="3">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">{{ firstHeader }}</p>
          <p class="ma-0 primary-text--text">
            <strong>{{ firstValue }}</strong>
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">{{ secondHeader }}</p>
          <div>
            <span class="text-truncate primary-text--text">{{
              secondValue
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column">
          <p class="ma-0 secondary-text--text">{{ thirdHeader }}</p>
          <div>
            <span class="primary-text--text" v-if="!isArray(thirdValue)">{{
              thirdValue
            }}</span>
            <template v-if="isArray(thirdValue)">
              <p
                class="primary-text--text"
                v-for="(value, i) in thirdValue"
                :key="`third-${i}`"
              >
                {{ value }}
              </p>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>
<script>
import _ from "lodash";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  computed: {
    isProducer() {
      return this.state.tipo_produtor.id === ProducerType.PRODUCER.id;
    },
    firstHeader() {
      return this.isProducer
        ? this.$t("labels.producer")
        : this.$t("labels.producer_group");
    },
    secondHeader() {
      return this.isProducer
        ? this.$t("labels.cpf_cnpj")
        : this.$t("labels.producer_group_email");
    },
    thirdHeader() {
      return this.isProducer
        ? this.$t("labels.email")
        : this.$t("labels.composition");
    },
    firstValue() {
      return this.state.produtor;
    },
    secondValue() {
      return this.isProducer ? this.state.cpf_cnpj : this.state.email;
    },
    thirdValue() {
      return this.isProducer ? this.state.email : this.state.composicao;
    }
  },
  methods: {
    isArray(value) {
      return _.isArray(value);
    }
  }
};
</script>
<style></style>
