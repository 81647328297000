<template>
  <div class="d-flex">
    <span :class="`${color}--text`">●</span
    ><span class="status-label ml-2 text-uppercase">&nbsp;<slot></slot></span>
  </div>
</template>
<script>
import { StatusColors } from "@/modules/core/enums/status-colors.enum";

export default {
  props: {
    value: {
      required: true,
      type: String
    }
  },
  computed: {
    color() {
      return StatusColors.getColor(this.value);
    }
  }
};
</script>
<style lang="scss" scoped>
.status-label {
  font-size: 15px;
  text-transform: capitalize;
}
</style>
