export const ExceptionRequestStatus = {
  IN_REVIEW: {
    id: "EM_ANALISE",
    name: "Em análise",
    onSuccessAction: "analisada"
  },
  APPROVED: {
    id: "APROVADO",
    name: "Aprovado",
    onSuccessAction: "aprovada"
  },
  REPROVED: {
    id: "REPROVADO",
    name: "Reprovado",
    onSuccessAction: "reprovada"
  },
  CANCELED: {
    id: "CANCELADO",
    name: "Cancelado",
    onSuccessAction: "cancelada"
  }
};

export const getExceptionRequestStatusMessageById = id => {
  const messageAction = Object.values(ExceptionRequestStatus).find(
    requestStatus => requestStatus.id === id
  ).onSuccessAction;

  if (!messageAction) {
    return "alterada";
  }

  return messageAction;
};
