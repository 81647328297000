import { InviteStatus } from "@/modules/core/enums/invite-status.enum";
import { ExceptionRequestStatus } from "@/modules/exception-request/enums/exception-request-status.enum";
import { CancelInviteStatus } from "./cancel-invite-status.enum";

export const StatusColors = {
  [InviteStatus.INVITE_ACCEPTED.id]: "status-accepted",
  [InviteStatus.INVITE_REFUSED.id]: "status-refused",
  [InviteStatus.INVITE_SENT.id]: "status-send",
  [InviteStatus.INVITE_CANCELED.id]: "status-refused",

  [ExceptionRequestStatus.IN_REVIEW.id]: "status-send",
  [ExceptionRequestStatus.APPROVED.id]: "status-accepted",
  [ExceptionRequestStatus.REPROVED.id]: "status-refused",

  [CancelInviteStatus.CANCEL_INVITE_APPROVED]: "status-accepted",
  [CancelInviteStatus.CANCEL_INVITE_CANCELED]: "status-refused",
  [CancelInviteStatus.CANCEL_INVITE_DISAPPROVED]: "status-refused",
  [CancelInviteStatus.CANCEL_INVITE_IN_REVIEW]: "status-send",

  getColor(status) {
    if (!this[status]) {
      throw new Error("Invalid status");
    }
    return this[status];
  }
};
